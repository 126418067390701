.dashboard {
  height: 100%;

  .MuiTab-root {
    padding: 16px;
  }

  div[role="tabpanel"] {
    height: 100%;

    & > div {
      padding: 0;
      height: 100%;
    }
  }
}

.dashboard-overview {
  h2 {
    font-size: 26px;
    line-height: 35px;
  }

  .card {
    border: 1px solid $main-grey200;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 180px;
  }

  .incentives {
    background-color: $main-grey100;

    h1 {
      font-weight: 400;
      font-size: 40px;
      line-height: 53px;
      margin: 16px;
    }

    .position {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p,
    li {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  }

  .line {
    height: 1px;
    width: 90%;
    background-color: $main-grey200;
  }
}

.dashboard-indicator {
  width: 8px;
  height: 100%;
  max-height: 30px;
  opacity: 0.5;
  border-radius: 4px;
  min-height: 30px;
  display: block;
}

.dashboard-table {
  height: 100%;

  .dashboard-table-sidebar {
    background-color: $bg-grey;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    height: 100%;
    margin-left: -20px;

    .MuiListItem-root {
      text-align: right;
      justify-content: right;
      padding-right: 20px;
      font-size: 1.2em;
      font-weight: lighter;
    }

    .MuiListItemText-primary {
      text-align: right;
    }

    .Mui-selected {
      background-color: $bg-grey;
      border-right: 4px solid $main-blue;
    }
  }

  tr.MuiTableRow-root td:first-child {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  }
}

.dashboard-overlay {
  position: relative;
  height: 100%;

  .sidebar {
    background-color: $bg-grey;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    height: 100%;
    margin-left: -20px;

    .MuiListItemText-primary {
      text-align: right;
    }

    .Mui-selected {
      background-color: $bg-grey;
      border-right: 4px solid $main-blue;
    }
  }

  .contracts-content {
    padding: 24px;
    overflow-y: scroll;
    h2 {
      font-weight: 400;
      font-size: 26px;
      line-height: 35px;
    }

    .line {
      height: 1px;
      width: 90%;
      background-color: $main-grey200;
    }

    .section-title {
      margin-top: 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    .value-column {
      display: flex;
      flex-direction: column;

      & > div {
        margin: 10px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .footer {
    position: fixed;
    padding: 24px;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.25);
    bottom: 0;
    width: 65vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: $main-white;

    .kpi-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      border-right: 1px solid $main-grey200;

      &:last-of-type {
        border-right: 0;
      }

      h2 {
        margin: 0 0 8px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: $main-grey600;
      }
    }
  }
}
