.new-customer {
  .new-customer-header {
    display: flex;
    align-items: center;
    min-height: 100px;

    h1 {
      font-size: 40px;
      font-weight: 400;
      flex-grow: 1;
    }

    button,
    a {
      margin-left: 10px;
      height: 28px;
    }
  }

  .customer-type-selection {
    background-color: #252d36;
    color: $main-white;
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px;

    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.7);
    }

    .MuiInput-root {
      color: $main-white;

      &:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid $main-white;
      }

      .MuiSvgIcon-root {
        fill: $main-white;
      }
    }
  }
}

ul[aria-labelledby="demo-simple-select-standard-label"] {
  background-color: #121212;
  color: $main-white;

  .menu-item.Mui-selected {
    background-color: rgba(0, 141, 252, 0.16);
  }
}
