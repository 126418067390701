.loading-page {
  z-index: 9990;
  background-color: transparent;

  .circular {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .text {
    padding-top: 30px;
    color: $main-black;
    font-weight: 500;
    font-size: 16.8px;
    line-height: 19px;
    min-width: 130px;
    display: flex;
    justify-content: center;
  }

  .page {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 50px;
    border-radius: 8px;
  }
}
