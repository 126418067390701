.split-panel {
  position: relative;
  flex-shrink: 0;
  overflow: visible;

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;

    .fade-left {
      height: 100%;
    }

    > .button-container {
      position: sticky;
      margin-right: -30px;
      bottom: 40%;
      display: flex;
      flex-direction: row-reverse;

      .MuiFab-root {
        width: 30px;
        height: 30px;
        min-width: unset;
        min-height: unset;
      }
      //.MuiButton-root {
      //  border-radius: 100%;
      //  width: 24px;
      //  height: 24px;
      //  background: red;
      //}
    }
  }
}
