//@import "@mercedes-benz/typeface-mb-corpo-a/index.css";
//@import "@mercedes-benz/typeface-mb-corpo-s/index.css";

main {
  padding: 0 20px;
  flex-grow: 1;
}

h1,
h2,
h3 {
  font-family: "MB Corpo A Title Condensed", "MB Corpo S Title Condensed", serif;
  font-weight: 400;
}

body {
  font-family: "MB Corpo S Text", "Arial", serif;
}
