.side-drawer {
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  width: 100%;

  .sidebar-dark {
    position: relative;
    background-color: $bg-blue;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    height: 100%;
    margin-left: -20px;

    > .button-container {
      position: absolute;
      margin-right: -10px;
      top: 80%;
      right: 0;
      display: flex;
      flex-direction: row-reverse;

      .MuiFab-root {
        width: 30px;
        height: 30px;
        min-width: unset;
        min-height: unset;
      }
    }

    .MuiListItemButton-root {
      color: $main-white;
      opacity: 0.6;
    }

    .MuiListItemText-primary {
      text-align: right;
      padding-right: 8px;
    }

    .MuiListItemIcon-root {
      min-width: unset;
      padding: 0 8px;
    }

    .Mui-selected {
      opacity: 1;
      background-color: $bg-blue;
      border-right: 4px solid $main-blue;
      color: $main-white;

      .MuiListItemIcon-root {
        padding-right: 4px;
      }
    }
  }

  .sidebar-content {
    flex: 1;
  }
}
