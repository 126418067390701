.enhanced-table {
  width: 100%;

  .MuiTableCell-root {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  th.MuiTableCell-head {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }

  tr.MuiTableRow-root.locked {
    opacity: 0.5;
  }
}
