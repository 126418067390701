.chip {
  display: flex;
  gap: 0.5em;
  align-items: center;

  .MuiChip-root {
    background-color: $main-grey400;
    color: $main-white;
  }
}
