.new-deal {
  height: calc(100vh - 64px);
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  .stepper {
    padding-left: 25%;
    padding-right: 25%;
  }

  hr.MuiDivider-vertical {
    margin-right: -1.5px;
  }

  .content {
    flex-grow: 1;

    .form {
      display: flex;
      width: 100%;

      .right {
        width: 100%;
        overflow-x: hidden;
      }

      .split-panel {
        left: -20px;
        box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15);
        clip-path: inset(0px -15px 0px 0px);

        .left {
          width: 50vw;
          min-width: 850px;
          padding: 20px 20px 0;

          .model {
            height: fit-content;
            overflow-x: visible;
            .model-tree {
              height: calc(100vh - 500px);
              min-height: 460px;
              overflow-y: scroll;
              overflow-x: hidden;
            }
          }

          .customers {
            .enhanced-table {
              height: 65vh;
              overflow-y: scroll;
              overflow-x: hidden;
            }

            .three-items .search-container {
              grid-template-columns: 50% 140px 130px;
            }

            .four-items .search-container {
              grid-template-columns: 30% 180px 120px 180px;
            }
          }

          .view-header .button-container {
            padding-right: 40px;
            padding-bottom: 20px;
          }

          .import-section {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
              font-size: 28px;
            }

            button {
              height: 28px;
            }
          }
        }
      }
    }
  }

  .search-model {
    background-color: #252d36;
    color: $main-white !important;
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px;

    .MuiInputLabel-root {
      color: $main-white !important;
    }

    .MuiInput-root {
      color: $main-white;

      &:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid $main-white;
      }

      .MuiSvgIcon-root {
        fill: $main-white !important;
      }
    }
  }
}

.new-deal-drawer {
  .MuiTab-textColorPrimary {
    align-items: flex-end;
    text-align: end;
  }

  [role="tabpanel"] > div {
    padding: 16px;
  }

  .footer-value {
    font-family: MB Corpo A Title Cond WEB;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  .footer-label {
    font-family: MB Corpo S Text;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    text-align: center;
    max-width: 130px;
  }
}
