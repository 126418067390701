﻿.searchinfo-panel {
  display: flex;
  padding: 8px 24px;
  gap: 8px;
  background: #f2f2f2;
  color: #5c5c5c;
  flex: none;
  flex-grow: 0;
  flex-direction: row;
  order: 2;
  align-self: stretch;
  align-items: flex-start;
  font-family: "MB Corpo S Text", "Arial", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-left: -20px;
  margin-right: -20px;
}
