.edit-deal {
  .sidebar {
    background-color: $bg-blue;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    height: 100%;
    margin-left: -20px;

    .MuiListItemButton-root {
      color: $main-white;
      opacity: 0.6;
    }
    .MuiListItemText-primary {
      text-align: right;
      padding-right: 8px;
    }

    .MuiListItemIcon-root {
      min-width: unset;
      padding: 0 8px;
    }

    .Mui-selected {
      opacity: 1;
      background-color: $bg-blue;
      border-right: 4px solid $main-blue;
      color: $main-white;

      .MuiListItemIcon-root {
        padding-right: 4px;
      }
    }
  }
}

.insurance-drawer,
.insurance-print-dialog {
  h3 {
    font-family: MB Corpo S Text;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .footer-value {
    font-family: MB Corpo A Title Cond WEB;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }

  .footer-label {
    font-family: MB Corpo S Text;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    text-align: center;
    max-width: 130px;
  }
  .form-radio-button > .MuiFormLabel-colorPrimary {
    color: black;
  }
}

.sales-person-dialog {
  min-width: 20em;
  min-height: 2em;

  > div {
    padding: 1em ;
  }
}