.MuiFormControl-root .MuiInput-root {
  .MuiIconButton-root {
    margin-right: -8px;
  }
  .MuiSvgIcon-root {
    fill: #0078d6;
  }
}

.MuiFormControlLabel-root.Mui-error {
  color: #d92121;
}

.price-input {
  width: 100%;
  .MuiFormControl-root {
    margin: 0;
    padding-right: 0;
    flex: 1;

    .Mui-disabled:before {
      border-bottom-style: solid;
    }
  }
}
