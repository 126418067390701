.search-panel {
  background-color: $main-white;

  .MuiCheckbox-root:not(.Mui-checked){
    color: $main-white;
  }

  &.search-header {
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px;
    background-color: $bg-blue;
    color: $main-white;

    .toggle-search {
      color: $main-white;
    }

    .reset {
      color: $main-white;
    }

    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.7);
    }

    .MuiInput-root {
      color: $main-white;

      &:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid $main-white;
      }

      .MuiSvgIcon-root {
        fill: $main-white !important;
      }
    }
  }

  .toggle-search {
    font-size: 14px;
  }

  .reset {
    font-size: 14px;
  }

  &.two-items {
    .search-container {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: auto 180px;
    }
  }

  &.three-items {
    .search-container {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 30% 140px 180px;
    }
  }

  &.four-items {
    .search-container {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 250px 30% 140px 180px;
    }
  }

  &.advanced {
    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      button[type="submit"] {
        height: 64px;
        margin-bottom: 20px;
      }
    }
  }
}
