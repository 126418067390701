.customers {
  .customer-list {
    margin-top: 16px;
  }

  tr.MuiTableRow-hover {
    cursor: pointer;
  }

  tr.MuiTableRow-root .MuiSvgIcon-root {
    fill: rgba(0, 0, 0, 0.6);
  }

  .four-items .search-container {
    grid-template-columns: 30% 200px 140px 180px;
  }
}

.private-customer-form {
  padding-top: 20px;

  div.MuiBox-root {
    padding: 0;
  }

  .personal-data,
  .income,
  .finance {
    &.MuiGrid-root {
      padding: 24px 0;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    .split-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      > div.consent-form {
        margin-left: 8px;
        gap: 40px;
      }
    }

    .form-radio-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > label {
        min-width: 8em;
        max-width: 10em;
      }
    }
  }

  .income > div {
    flex: 0 1 25%;
  }
}

.potential-customer-form {
  display: flex;
  flex-direction: column;
}

.commercial-customer-form {
  padding-top: 20px;

  .MuiTextField-root {
    margin: 8px;
    width: unset;
  }

  div.MuiBox-root {
    padding: 0;
  }

  .entry-contact,
  .partner {
    &.MuiGrid-root {
      padding: 24px 0;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    .split-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      > div.consent-form {
        margin-left: 8px;
        gap: 40px;

        
      .form-radio-button > label {
        width: 8em;
      }
      }
    }

    .risk-increase {
      .form-radio-button {
        flex-direction: column;
        align-items: flex-start;

        label {
          white-space: pre-line;
        }
      }
    }

    .form-radio-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .income > div {
    flex: 0 1 25%;
  }
}

.MuiChip-root.customer-chip {
  border-radius: 4px;
  color: #5c5c5c;

  &.MuiChip-sizeMedium {
    width: 32px;
  }

  &.MuiChip-colorWarning {
    background: #fdeb99;
  }

  &.MuiChip-colorInfo {
    background: #b4ddfe;
  }
}
